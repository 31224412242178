<template>
<div class="inspect-index">
  <el-page-header @back="goBack" :content="title"></el-page-header>
  <el-backtop target=".el-main"></el-backtop>
  <div class="content" v-loading="loading">
    <el-tabs>
      <el-tab-pane v-for="(tab, index1) of tabList" :key="index1" :label="tab.name">
        <div class="group-item" v-for="(group, index2) of tab.list" :key="index2">
          <div class="group-title" v-if="object_pid != 7">
            {{ group.name }}<span>（分值：{{ group.score }}分)</span>
          </div>
          <div class="q-item" v-for="(q, index3) of group.list" :key="index3">
            <template v-if="!q.jumped">
              <div class="q-title">{{ q.name }}</div>
              <div style="margin: 10px 0;">
                <el-tag style="margin-right: 10px;width:66px;text-align:center;" size="small">
                  <span v-if="q.type == 3">多选题</span>
                  <span v-if="q.type == 2">单选题</span>
                  <span v-if="q.type == 1">选填数字</span>
                </el-tag>
                <el-link :underline="false" type="primary" @click="showEg(q)">如何评估？</el-link>
              </div>
              <div class="a-wrap a-checkbox" v-if="q.type == 3">
                <el-checkbox-group :disabled="readOnly" v-model="q.checkbox" @change="checkboxChange(q)">
                  <el-checkbox class="a-item" v-for="(a, index4) of q.options" :key="index4" :label="a.option_id">{{ a.name }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="a-wrap a-radio" v-if="q.type == 2">
                <el-radio :disabled="readOnly" class="radio" v-for="(a, index4) of q.options" :key="index4" :value="q.radio" :label="a.option_id*1"><span style="padding-left: 30px;position: relative;left: -30px;" @click="readOnly?'':radioChange(q,a)">{{ a.name }}</span></el-radio>
              </div>
              <div class="a-wrap a-select" v-if="q.type == 1">
                <div class="select" v-for="(a, index4) of q.options" :key="index4">
                  <el-select @change="selectChange(q,a)" :disabled="readOnly" size="mini" class="left" v-model="a.real_score" placeholder="点击评分">
                    <el-option v-for="item in a.scoreList" :key="item" :label="item + '分'" :value="item">
                    </el-option>
                  </el-select>
                  <span class="right">{{ a.name }}</span>
                </div>
              </div>
              <template v-if="inspectStatus == 1">
                <div class="score">得分：{{ q.real_score !== '' ? q.real_score+'分': '' }}</div>
              </template>
              <template v-if="inspectStatus == 2">
                <div class="score">自评得分：{{ q.real_score !== '' ? q.real_score+'分': '未自评' }}</div>
              </template>
              <!-- 审核中 市级或督导查看 -->
              <template v-if="inspectStatus == 3 && role != 4">
                <!-- 机构类型 -->
                <template v-if="inspect_status.type == 1">
                  <div class="score">自评得分：{{ q.real_score !== '' ? q.real_score+'分': '未自评' }}</div>
                  <div class="admin-score" style="color: #f88000" v-if="jumpQid.indexOf(q.number) == -1">
                    评审结果：
                    <el-input v-if="q.type == 3" placeholder="输入评分" size="mini" @blur="onblur(q)" oninput="value=value.replace(/[^\d.]/g,'')" style="width: 80px;" v-model="q.examine_score">
                    </el-input>
                    <el-select v-if="q.type != 3" filterable class="admin-select" size="mini" v-model="q.examine_score" placeholder="点击评分">
                      <el-option v-for="item of q.exOption" :key="item" :label="item + '分'" :value="item">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="admin-score" style="color: #f88000" v-else>
                    评审结果：{{'0分'}}
                  </div>
                  <!-- jumpQid.indexOf(q.number) 如果是跳过题，则不打分 -->
                  <div class="admin-score" style="color: #f88000" v-if="jumpQid.indexOf(q.number) == -1">
                    评审说明：<el-input type="textarea" :rows="2" placeholder="请输入内容" size="mini" v-model="q.examine_content">
                    </el-input>
                  </div>
                </template>
                <!-- 市级类型 -->
                <template v-if="inspect_status.type == 2 && role == 3">
                  <div class="admin-score" style="color: #f88000">
                    评审结果：{{ q.real_score !== '' ? q.real_score+'分': '' }}
                  </div>
                  <div class="admin-score" style="color: #f88000">
                    评审说明：<el-input @blur="contentBlur(q)" type="textarea" :rows="2" placeholder="请输入内容" size="mini" v-model="q.examine_content">
                    </el-input>
                  </div>
                </template>
                <template v-if="inspect_status.type == 2 && (role == 2 || role == 1)">
                  <div class="admin-score" style="color: #f88000">
                    评审结果：{{ q.real_score !== '' ? q.real_score+'分': '未评审' }}
                  </div>
                  <div class="admin-score" v-if="q.examine_content" style="color: #f88000">
                    评审说明：{{q.examine_content || '无'}}
                  </div>
                </template>
                <!-- 督导类型 -->
                <template v-if="inspect_status.type == 3">
                  <div class="admin-score" style="color: #f88000">
                    评审结果：{{ q.real_score !== '' ? q.real_score+'分': '' }}
                  </div>
                  <div class="admin-score" style="color: #f88000">
                    评审说明：<el-input @blur="contentBlur(q)" type="textarea" :rows="2" placeholder="请输入内容" size="mini" v-model="q.examine_content">
                    </el-input>
                  </div>
                </template>
              </template>
              <template v-if="inspectStatus == 4">
                <template v-if="isSchoolType">
                  <div class="score">自评得分：{{ q.real_score !== '' ? q.real_score+'分': '未自评' }}</div>
                  <div v-if="isAdminUser" class="admin-score" style="color: #f88000">
                    评审结果：{{ q.examine_score !== '' ? q.examine_score+'分': '未打分' }}
                  </div>
                  <div class="admin-score" style="color: #f88000" v-if="isAdminUser && q.examine_name">
                    评审专家：{{q.examine_name}}
                  </div>
                  <div v-if="isAdminUser && q.examine_content" class="admin-score" style="color: #f88000">
                    评审说明： {{ q.examine_content }}
                  </div>
                </template>
                <template v-if="isAdminType">
                  <div class="admin-score" style="color: #f88000">
                    评审结果：{{ q.real_score !== '' ? q.real_score+'分': '未评审' }}
                  </div>
                  <div class="admin-score" style="color: #f88000" v-if="q.examine_name">
                    评审专家：{{q.examine_name}}
                  </div>
                  <div class="admin-score" v-if="q.examine_content" style="color: #f88000">
                    评审说明：{{q.examine_content || '无' }}
                  </div>
                </template>
              </template>
              <template v-if="isSchoolType">
                <div v-if="inspect_status.submit_status == 1 && q.files.length == 0" style="overflow: hidden;">
                  <div class="el-collapse-item__header">证明材料<i class="el-icon-arrow-right" style="margin-left: 3px"></i></div>
                  <div style="width: 300px;position: relative; float:left;margin: -20px 0 40px;left:0;transform: none;" class="el-message el-message--warning is-closable">
                    <i class="el-message__icon el-icon-warning"></i>
                    <p class="el-message__content">未上传证明材料</p>
                  </div>
                </div>
                <el-collapse v-model="q.collapse" accordion v-else>
                  <el-collapse-item name="1">
                    <template slot="title">
                      证明材料<i class="el-icon-arrow-right" style="margin-left: 3px"></i>
                    </template>
                    <div class="upload">
                      <div class="upload-group">
                        <h3>图片<span>（最多可上传9张图片）</span></h3>
                        <ul class="el-upload-list el-upload-list--picture-card">
                          <li class="el-upload-list__item is-success" v-for="(file, index) of q.imgList" :key="index">
                            <div class="df">
                              <el-image :src="file.url" fit="cover"></el-image>
                              <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview1(file)">
                                  <i class="el-icon-zoom-in"></i>
                                </span>
                                <span class="el-upload-list__item-delete" v-if="!readOnly" @click="ObsDelete(index1, index2, index3, 'imgList', index)">
                                  <i class="el-icon-delete"></i>
                                </span>
                              </span>
                            </div>
                          </li>
                        </ul>
                        <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow1">
                          <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText1" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
                          </li>
                        </ul>
                        <el-upload v-if="!readOnly" action="#" list-type="picture-card" accept="image/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, index1, index2, index3, 'imgList',)}" :class="{ 'max-length': (q.imgList.length == 9 || (uploadShow1)) }">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </div>
                      <div class="upload-group">
                        <h3>
                          视频<span>（视频可上传1-3个，支持MP4、MOV格式）</span>
                        </h3>
                        <ul class="el-upload-list el-upload-list--picture-card">
                          <li class="el-upload-list__item is-success" v-for="(file, index) of q.videoList" :key="index">
                            <div style="height:100%;" class="video_bg">
                              <video :src="file.url" style="width: 100%"></video>
                              <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview2(file)">
                                  <i class="el-icon-video-play"></i>
                                </span>
                                <span class="el-upload-list__item-delete" v-if="!readOnly" @click="ObsDelete(index1, index2, index3, 'videoList', index)">
                                  <i class="el-icon-delete"></i>
                                </span>
                              </span>
                            </div>
                          </li>
                        </ul>
                        <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow2">
                          <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText2" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
                          </li>
                        </ul>
                        <el-upload v-if="!readOnly" action="#" list-type="picture-card" accept="video/mp4,video/x-m4v,video/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, index1, index2, index3, 'videoList',)}" :class="{ 'max-length': (q.videoList.length == 3 || (uploadShow2)) }">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </div>
                      <div class="upload-group filelist-group">
                        <h3>
                          文档/压缩包<span>（可上传1-6个文档或压缩包，文档支持Word、Excel、PPT、PDF格式）</span>
                        </h3>
                        <ul class="el-upload-list el-upload-list--picture-card">
                          <li class="el-upload-list__item is-success" v-for="(file, index) of q.fileList" :key="index">
                            <div class="file-list-bg">
                              <div class="file-item">
                                <span class="ellipsis" style="width: 80%;"><a :href="file.url" target="_blank">{{ file.file_name }}</a></span>
                                <i class="el-icon-delete" v-if="!readOnly" style="cursor: pointer" @click="ObsDelete(index1, index2, index3, 'fileList', index)"></i>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow3">
                          <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText3" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
                          </li>
                        </ul>
                        <el-upload v-if="!readOnly" action="#" list-type="picture-card" :show-file-list="false" accept="application/msword, application/pdf, .zip,.rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,application/octet-stream, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .docx, .doc, .ppt, .pptx" :http-request="(e)=>{ObsUpload(e, index1, index2, index3, 'fileList',)}" :class="{ 'max-length': (q.fileList.length == 6 || (uploadShow3)) }">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </template>
            </template>
            <div v-else class="q-title" style="color: #666"><span>（已跳过）</span>{{ q.name }}</div>
          </div>
        </div>
        <template v-if="inspectStatus == 1 || (inspectStatus == 3 && isAdminType)">
          <el-button class="save-question" @click="saveAll" :loading="submitLoading" type="primary">保存</el-button>
        </template>
        <template v-if="inspectStatus == 3 && isSchoolType">
          <el-button class="save-question" @click="saveExamine" :loading="submitLoading" type="primary">保存</el-button>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="dialogClose" width="70%">
    <p style="line-height: 2;font-size: 16px;padding:0 30px 0;text-align:left;font-weight:bold;" v-if="dialogText1">评估方式与信息采集：</p>
    <div v-if="dialogText1" v-html="dialogText1" style="font-size: 16px;line-height: 2;padding:0 30px 30px;text-align:left;"></div>
    <p style="line-height: 2;padding:0 30px 0;font-size: 16px;text-align:left;font-weight:bold;" v-if="dialogText1">解释说明：</p>
    <div v-if="dialogText2" v-html="dialogText2" style="line-height: 2;font-size: 16px;padding:0 30px 30px;text-align:left;;"></div>
    <img v-if="dialogImageUrl" width="100%" :src="dialogImageUrl" alt="" />
    <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
  </el-dialog>
</div>
</template>

<script>
import obs from "@/utils/obs.js";
export default {
  name: "inspect-index",
  data() {
    return {
      inspect_id: "1",
      object_pid: "",
      title: "",
      loading: false,
      createLoading: false,
      activeName: 0,
      dialogImageUrl: "",
      dialogVideoUrl: "",
      disabled: false,
      dialogVisible: false,
      dialogText1: "",
      dialogText2: "",
      dialogTitle: "",
      isTest: location.href.indexOf("192.168") != -1 ||
        location.href.indexOf(".test.") != -1 ?
        "test/" : "",
      loading: true,
      uploadLength: 0,
      tabList: [],
      submitLoading: false,
      jumpList: {},
      jump_ids: [],
      uploadShow1: false,
      uploadShow2: false,
      uploadShow3: false,
      jumpQid: [],
      uploadFileNumber: 0,
      loadingText1: '上传中',
      loadingText2: '上传中',
      loadingText3: '上传中',
    };
  },
  mounted() {
    this.object_pid = this.$route.query.object_pid;
    this.inspect_id = this.$route.query.inspect_id;
    this.role = localStorage.getItem("role");
    document.querySelector('.main').scrollTop = 0;
    this.init();
  },
  methods: {
    contentBlur(q) {
      this.saveQuestion(q);
    },
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/object/info",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
        },
      });
      if (res.code == 0) {
        this.inspect_status = res.data.inspect || {
          submit_status: 0,
          examine_status: 0,
          type: 1
        };
        this.title = res.data.object_pname;
        this.time_id = res.data.time_id;
        // 基础数据
        // 循环到题的一层，添加checkbox value，filelist 字段
        if (this.object_pid == 7) {
          this.tabList = res.data.list.map((e) => {
            let obj = JSON.stringify(e);
            e = {
              name: e.name,
              list: [JSON.parse(obj)],
            };
            for (const e2 of e.list) {
              if (e2.list) {
                for (let e3 of e2.list) {
                  e3.examine_score = '';
                  e3.examine_content = '';
                  e3.jumped = false; // 跳过 
                  e3.real_score = '';
                  e3.imgList = [];
                  e3.videoList = [];
                  e3.fileList = [];
                  e3 = this.handleOption(e3);
                }
              }
            }
            return e;
          });
        } else {
          this.tabList = res.data.list.map((e1) => {
            for (const e2 of e1.list) {
              if (e2.list) {
                for (let e3 of e2.list) {
                  e3.jumped = false; // 跳过 
                  e3.examine_score = '';
                  e3.examine_content = '';
                  e3.real_score = '';
                  e3 = this.handleOption(e3);
                  if (e3.files) {
                    e3.collapse = e3.files.length ? ['1'] : []; // 有文件就展开
                    e3 = this.handleFile(e3);
                  }
                }
              }
            }
            return e1;
          });
          // 隐藏跳过的题
          this.hideJump();
        }
        // console.log(this.tabList);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.loading = false;
    },
    // 处理答案
    handleOption(e3) {
      // 选分题
      if (e3.type == 1) {
        let exScore = 0;
        let exOption = [];
        let pipei = [];
        e3.options.forEach(element => {
          element.score = element.score * 1;
          let list = []
          for (let index = element.score; index >= 0; index--) {
            list.push(index)
          }
          element.scoreList = list;
          pipei = e3.result_info.filter(e => {
            return e.option_id == element.option_id
          });
          element.real_score = pipei.length ? pipei[0].score * 1 : '';
          exScore += element.score;
        });
        let s = 0;
        for (const i of e3.options) {
          if (i.real_score) {
            s += i.real_score * 1
          }
        }
        e3.real_score = s || '';
        for (let index = exScore; index >= 0; index--) {
          exOption.push(index);
        }
        e3.exOption = exOption;
      } else if (e3.type == 2) {
        //判断是否是跳过的题
        let jumpFlag = e3.options.filter(e => {
          return e.jump_number != 0;
        });
        if (jumpFlag.length) {
          // console.log(jumpFlag, '有跳过的题');
          e3.hasJump = true;
          this.jumpQid.push(e3.number);
        }
        this.jumpQid = Array.from(new Set(this.jumpQid));
        // console.log('jumpQid', this.jumpQid)
        // 单选题
        let exOption = [];
        e3.radio = e3.result_info.length ? e3.result_info[0].option_id * 1 : "";
        e3.real_score = e3.result_info.length ? e3.result_info[0].score * 1 : "";
        if (e3.radio) {
          let list = e3.options.filter(e => {
            return e.option_id * 1 == e3.radio * 1
          });
          if (list.length) {
            let jump_number = list[0].jump_number;
            // console.log('有跳过的题', jump_number)
            if (jump_number > 0) {
              let ids = [];
              if (jump_number != 0) {
                for (let i = e3.number + 1; i < jump_number; i++) {
                  ids.push(i)
                }
              }
              this.jumpList[e3.number] = ids;
            }
            // console.log(this.jumpList)
            let index = this.jumpQid.indexOf(e3.number);
            // console.log(index);
            this.jumpQid.splice(index, 1);
            // console.log(this.jumpQid);
          }
        }
        e3.options.forEach(element => {
          exOption.push(element.score * 1)
        });
        e3.exOption = Array.from(new Set(exOption));
      } else if (e3.type == 3) {
        let exScore = 0;
        let exOption = [];
        // 多选题
        // 获得之前选择的答案 
        if (e3.result_info.length) {
          e3.checkbox = e3.result_info.length ? e3.result_info.map(e => {
            return e.option_id * 1;
          }) : [];
          // 计算之前选择的答案得分
          let s = 0;
          for (const i of e3.checkbox) {
            let a = e3.options.filter(e => {
              return e.option_id == i
            });
            if (a.length) {
              s += a[0].score * 1;
            }
          }
          e3.real_score = s;
        } else {
          e3.checkbox = [];
        }

        e3.options.forEach(element => {
          element.score = element.score * 1;
          exScore += element.score;
        });
        let s = 0;
        for (const i of e3.options) {
          s += i.real_score * 1;
        }
        for (let index = exScore; index >= 0; index--) {
          exOption.push(index);
        }
        e3.exOption = exOption;
      }
      // 审核结果分
      if (e3.result_info) {
        if (this.inspect_status.type == 1) {
          // 机构审核
          let examine = e3.result_info.filter(e => {
            return e.type == 2;
          });
          if (examine.length) {
            e3.examine_score = examine[0].score * 1;
            e3.examine_content = examine[0].content;
            e3.examine_name = examine[0].u_name;
          }
        } else if (this.inspect_status.type != 1) {
          //国家审核
          let examine = e3.result_info.filter(e => {
            return e.type == 1;
          });
          if (examine.length) {
            e3.examine_score = examine[0].score * 1;
            e3.examine_content = examine[0].content;
            e3.examine_name = examine[0].u_name;
          }
        }
        return e3
      }
    },
    onblur(e) {
      console.log(e)
      if(e.examine_score * 1 > e.exOption[0]) {
        e.examine_score = e.exOption[0]
      }
    },
    handleFile(e3) {
      // 处理文件
      e3.files.length && e3.files.forEach(e => {
        e.api_url = decodeURIComponent(e.url).replace('https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/', '')
      })
      e3.imgList = e3.files.filter(e => {
        return e.file_type == 1
      });
      e3.videoList = e3.files.filter(e => {
        return e.file_type == 2
      });
      e3.fileList = e3.files.filter(e => {
        return e.file_type == 3
      });
      return e3
    },
    dialogClose() {
      this.dialogText = "";
      this.dialogImageUrl = "";
      this.dialogVideoUrl = "";
    },
    deleteRow() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    showEg(q) {
      if (this.isAdminUser) {
        this.dialogText1 = q.ta_estimate;
        this.dialogText2 = q.ta_explain;
      } else {
        this.dialogText1 = q.estimate;
        this.dialogText2 = q.explain;
      }
      this.dialogVideoUrl = '';
      this.dialogImageUrl = '';
      this.dialogVisible = true;
      this.dialogTitle = "如何评估";
    },
    resetData() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    create() {
      this.createLoading = true;
      setTimeout(() => {
        this.createLoading = false;
        this.$router.push("/inspect");
      }, 2000);
    },
    radioChange(q, a) {
      if (q.radio == a.option_id) {
        q.radio = '';
        q.real_score = '';
        this.setJumpList(q, 0);
      } else {
        q.radio = a.option_id * 1;
        q.real_score = a.score * 1;
        this.setJumpList(q, a.jump_number);
      }
      // console.log(q, a.jump_number)
      this.saveQuestion(q);
    },
    setJumpList(q, jump_number) {
      console.log('jump_number', jump_number, this.jumpList)
      if (jump_number == 0) {
        if (this.jumpList[q.number] && this.jumpList[q.number].length) {
          // 有
          let ids = this.jumpList[q.number];
          for (const e1 of this.tabList) {
            for (const e2 of e1.list) {
              for (let e3 of e2.list) {
                if (ids.indexOf(e3.number) != -1) {
                  e3.jumped = false;
                  let index = this.jump_ids.indexOf(e3.object_id);
                  this.jump_ids.splice(index, 1);
                }
              }
            }
          }
          this.jumpList[q.number] = '';
        }
        return;
      } else {
        let ids = [];
        if (jump_number != 0) {
          for (let i = q.number + 1; i < jump_number; i++) {
            ids.push(i)
          }
        }
        this.jumpList[q.number] = ids;
        for (const e1 of this.tabList) {
          for (const e2 of e1.list) {
            for (let e3 of e2.list) {
              e3.jumped = false;
              if (ids.indexOf(e3.number) != -1) {
                e3.jumped = true;
                this.jump_ids.push(e3.object_id);
                e3.checkbox = [];
                e3.radio = '';
                e3.select = '';
                e3.real_score = '';
              }
            }
          }
        }
        this.jump_ids = Array.from(new Set(this.jump_ids))
        console.log(this.jump_ids)
      }
      // 根据问题number查找缓存，是否有跳过的题;

    },
    hideJump() {
      for (const key in this.jumpList) {
        if (Object.hasOwnProperty.call(this.jumpList, key)) {
          const ids = this.jumpList[key];
          for (const e1 of this.tabList) {
            for (const e2 of e1.list) {
              for (let e3 of e2.list) {
                if (ids.indexOf(e3.number) != -1) {
                  e3.jumped = true;
                  this.jump_ids.push(e3.object_id);
                  e3.checkbox = [];
                  e3.radio = '';
                  e3.select = '';
                  e3.real_score = '';
                }
              }
            }
          }
        }
      }
      // console.log(this.jump_ids)
    },
    selectChange(q, a) {
      // console.log(q, a);
      let s = 0;
      // console.log(q.options)
      for (const i of q.options) {
        s += i.real_score * 1;
      }
      q.real_score = s;
      this.saveQuestion(q);
    },
    checkboxChange(q, a) {
      // console.log(q, a);
      let s = 0;
      for (const i of q.checkbox) {
        s += q.options.filter(e => {
          return e.option_id == i
        })[0].score * 1;
      }
      // console.log(s)
      q.real_score = s;
      this.saveQuestion(q)
    },
    goBack() {
      this.$router.go(-1);
    },
    getFileDate() {
      let date = new Date();
      return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getTime()
    },
    async ObsUpload($event, tabIndex, groupIndex, qIndex, fileIndex) {
      let file = $event.file;
      if (!file.file_name) {
        file.file_name = file.name;
      }
      let q = this.tabList[tabIndex].list[groupIndex].list[qIndex]
      if (fileIndex == 'imgList' && q.imgList.length >= 9) {
        this.$message.warning(
          `当前限制选择9个图片`
        );
        return
      }
      if (fileIndex == 'videoList' && q.videoList.length >= 3) {
        this.$message.warning(
          `当前限制选择3个视频`
        );
        return
      }
      if (fileIndex == 'fileList' && q.fileList.length >= 6) {
        this.$message.warning(
          `当前限制选择6个文件`
        );
        return
      }

      let cb = '';
      if (fileIndex == 'imgList') {
        this.uploadShow1 = true;
        cb = this.uploadding1;
      }
      if (fileIndex == 'videoList') {
        this.uploadShow2 = true
        cb = this.uploadding2;
      }
      if (fileIndex == 'fileList') {
        this.uploadShow3 = true
        cb = this.uploadding3;
      }
      let pahtName = `yili_inspect/inspect_${this.inspect_id}/pid_${this.object_pid}/id_${q.object_id}`;
      let bucketName = 'servicetuoyusaas';
      let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;

      this.uploadFileNumber += 1;
      let res = await obs.upload(file, fileName, bucketName, cb);
      console.log(pahtName)
      // console.log("upload", res);
      if (res == "success") {
        try {
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/"+ fileName;
          let list = q[
            fileIndex
          ];
          console.log(list);
          // console.log(url);
          this.uploadShow1 = false;
          this.uploadShow2 = false;
          this.uploadShow3 = false;
          list.push({
            api_url: fileName,
            file_name: file.file_name,
            url: url
          });
          console.log(list);
          this.saveFile(q)
          return true;
        } catch (error) {
          this.$message("上传失败");
          console.log(error);
        }
      } else {
        this.$message("上传失败，请重试");
        this.uploadShow1 = false;
        this.uploadShow2 = false;
        this.uploadShow3 = false;
      }
      this.uploadFileNumber -= 1;
      // console.log(list);
    },
    uploadding1(e, size) {
      console.log(e, size)
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText1 = '上传 ' + r
    },
    uploadding2(e, size) {
      console.log(e, size)
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText2 = '上传 ' + r
    },
    uploadding3(e, size) {
      console.log(e, size)
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText3 = '上传 ' + r
    },
    async saveFile(item) {
      let files_json = [];
      let {
        imgList,
        videoList,
        fileList
      } = item;
      for (const iterator of imgList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 1,
          file_name: iterator.file_name
        })
      }
      for (const iterator of videoList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 2,
          file_name: iterator.file_name
        })
      }
      for (const iterator of fileList) {
        files_json.push({
          url: iterator.api_url,
          file_type: 3,
          file_name: iterator.file_name
        })
      }
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/files_save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          object_id: item.object_id,
          result_json: files_json.length ? JSON.stringify(files_json) : ''
        }
      });
      if (res.code == 'timeout') {
        this.$alert('<p style="color:red;font-size: 16px;">网络连接超时，上传失败。请刷新后重新上传。</p>', '提示', {
          dangerouslyUseHTMLString: true,
          showClose: false,
          confirmButtonText: '刷新',
          callback: (e) => {
            if (e == 'confirm') {
              this.init();
            }
          }
        });
        return;
      }
      if (res.code == 0) {
        // console.log('保存文件成功', files_json)
        console.log(item.files.length, res.data.files.length)
        if (item.files.length < res.data.files.length) {
          this.$message({
          message: '保存成功',
          type: 'success'
        });;
        } else {
          this.$message("删除成功");
        }
        item.files = res.data.files;
        item = this.handleFile(item);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async ObsDelete(index1, index2, index3, fileIndex, index) {
      let q = this.tabList[index1].list[index2].list[index3];
      let list = q[fileIndex]
      console.log(list, index);
      // console.log(url);
      list.splice(index, 1);
      console.log("删除index", index);
      this.saveFile(q)
    },
    handleRemove(file) {
      return this.$confirm(`确定移除 ${file.file_name}？`);
    },
    async saveAll() {
      console.log(this.uploadFileNumber);
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          time_id: this.time_id,
          jump_ids: String(this.jump_ids),
          get_number: 1
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });;
        this.$router.go(-1);
      } else if (res.code == 1) {
        this.$confirm(`有${res.data.not_number}项没有评估，是否保存。`, "提示", {
            confirmButtonText: "保存",
            cancelButtonText: "继续评估",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(() => {
            this.saveApi();
          })
          .catch(() => {});
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
    async saveExamine() {
      this.submitLoading = true;
      let length = 0;
      let result_json = [];
      for (const e1 of this.tabList) {
        for (const e2 of e1.list) {
          for (let e3 of e2.list) {
            length++;
            if (e3.examine_score !== '') {
              result_json.push({
                object_id: e3.object_id,
                score: e3.examine_score,
                content: e3.examine_content
              })
            }
          }
        }
      }
      for (const key in this.jumpList) {
        if (Object.hasOwnProperty.call(this.jumpList, key)) {
          const element = this.jumpList[key];
          for (const iterator of element) {
            length--;
          }
        }
      }

      console.log(length, result_json.length)
      if (result_json.length < length) {
        this.$confirm(`有${length - result_json.length}项没有评审，是否保存。`, "提示", {
            confirmButtonText: "保存",
            cancelButtonText: "继续评审",
            type: "warning",
            center: true,
            customClass: "customConfirm",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonClass: "cancelButtonClass",
          })
          .then(async () => {
            let res = await this.$api({
              method: "post",
              url: "/api/inspect/examine_save",
              data: {
                inspect_id: this.inspect_id,
                object_pid: this.object_pid,
                time_id: this.time_id,
                result_json: result_json.length ? JSON.stringify(result_json) : '',
              }
            });
            this.submitLoading = false;
            if (res.code == 0) {
              this.$message({
          message: '保存成功',
          type: 'success'
        });;
              this.$router.go(-1);
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            this.submitLoading = false;
          });
      } else {
        let res = await this.$api({
          method: "post",
          url: "/api/inspect/examine_save",
          data: {
            inspect_id: this.inspect_id,
            object_pid: this.object_pid,
            time_id: this.time_id,
            result_json: result_json.length ? JSON.stringify(result_json) : '',
          }
        });
        this.submitLoading = false;
        if (res.code == 0) {
          this.$message({
          message: '保存成功',
          type: 'success'
        });;
          this.$router.go(-1);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      }
    },
    async saveApi() {
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          time_id: this.time_id,
          jump_ids: String(this.jump_ids),
          get_number: 0,
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });;
        this.$router.go(-1);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async saveQuestion(item) {
      let json = [];
      let object_type = item.type; //题型；
      let object_id = item.object_id;
      let options = item.options;
      console.log(item)

      if (object_type == 3) {
        let checkbox = JSON.parse(JSON.stringify(item.checkbox));
        for (let i of checkbox) {
          let op = options.filter(e => {
            return e.option_id == i;
          })
          json.push({
            score: op[0].score,
            content: item.examine_content,
            option_id: op[0].option_id,
            object_type,
          })
        }
      } else if (object_type == 2) {
        if (item.radio) {
          json.push({
            score: item.real_score,
            content: item.examine_content,
            option_id: item.radio,
            object_type,
          })
        }

      } else if (object_type == 1) {
        let op = options.filter(e => {
          return e.real_score !== '';
        })
        for (let i of op) {
          json.push({
            score: i.real_score,
            content: item.examine_content,
            option_id: i.option_id,
            object_type,
          })
        }
      }
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/single_save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          object_id: object_id,
          jump_ids: String(this.jump_ids),
          result_json: JSON.stringify(json),
        }
      });
      if (res.code == 'timeout') {
        this.$alert('<p style="color:red;font-size: 16px;">网络连接超时，上传失败。请刷新后重新上传。</p>', '提示', {
          dangerouslyUseHTMLString: true,
          showClose: false,
          confirmButtonText: '刷新',
          callback: (e) => {
            if (e == 'confirm') {
              this.init();
            }
          }
        });
        return;
      }
      if (res.code == 0) {
        console.log('保存成功', json)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handlePictureCardPreview1(file) {
      this.dialogText1 = '';
      this.dialogText2 = '';
      this.dialogVideoUrl = '';
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.dialogTitle = file.file_name;
    },
    handlePictureCardPreview2(file) {
      this.dialogText1 = '';
      this.dialogText2 = '';
      this.dialogImageUrl = '';
      this.dialogVideoUrl = file.url;
      this.dialogVisible = true;
      this.dialogTitle = file.file_name;
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  computed: {
    inspectStatus() {
      if (this.inspect_status) {
        let {
          submit_status,
          examine_status,
          type
        } = this.inspect_status;
        let isSchoolType = localStorage.getItem("role") == 4;
        if (submit_status == 0 && examine_status == 0) {
          // 机构未评审
          return 1
        } else if (submit_status == 1 && examine_status == 0) {
          // 督导未评审
          return 2
        } else if (submit_status == 1 && examine_status == 2) {
          // 评审中
          return 3
        } else if (submit_status == 1 && examine_status == 1) {
          // 已评审
          return 4
        }
      } else {
        return 0
      }
    },
    readOnly() {
      if (this.inspect_status) {
        let r =
          (this.inspect_status.examine_status == 1) ||
          (this.role == 4 && this.inspect_status.submit_status == 1) ||
          (this.role == 3 && this.inspect_status.submit_status == 1 && this.inspect_status.type == 1) ||
          ((this.role == 2 || this.role == 1) && this.inspect_status.type == 1) ||
          ((this.role == 2 || this.role == 1) && this.inspect_status.type == 2)
        console.log('readOnly', this.role, this.inspect_status.type, r)
        return r;
      } else {
        return false
      }
    },
    isAdminType() {
      return this.inspect_status.type == 2 || this.inspect_status.type == 3
    },
    isSchoolType() {
      return this.inspect_status.type == 1
    },
    isAdminUser() {
      return localStorage.getItem("role") != 4
    },
    isSchoolUser() {
      return localStorage.getItem("role") == 4
    }
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.inspect-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .video_bg {
    background: url('../../assets/index/video_bg.png') no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
  }

  .df {
    display: flex;
    align-items: center;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .group-item {
      margin-bottom: 20px;
    }

    .group-title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      padding-bottom: 10px;
      border-bottom: 1px solid #e4e7ed;

      span {
        color: #666;
        font-size: 12px;
      }
    }

    .q-item {
      text-align: left;
      border-bottom: 1px solid #e4e7ed;
      padding: 20px 0;

      .q-title {
        color: #333;
        font-size: 15px;
      }

      .a-wrap {
        margin-top: 10px;
      }

      .a-item {
        color: #333;
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
        display: flex;
        letter-spacing: 1px;
      }

      .radio {
        display: flex;
        margin: 10px 0;
      }

      .score {
        color: #0083f5;
        margin-top: 20px;
        display: flex;
        align-items: center;
      }

      .admin-score {
        margin-top: 10px;
        line-height: 25px;
        display: flex;
        align-items: flex-start;

        .admin-select {
          width: 86px;
        }
      }

      .select {
        display: flex;
        margin: 10px 0;

        .left {
          flex: 0 0 70px;
          width: 70px;
          margin-right: 10px;
        }

        .right {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }

    .save-question {
      width: 260px;
      float: left;
      margin: 50px auto;
    }
  }

  .upload {
    padding-bottom: 20px;
    background: #f7f8fa;

    .upload-del {
      position: absolute;
      right: -9px;
      top: -11px;
      width: 22px;
      height: 22px;
    }

    .file-list-bg {
      width: 345px;
      background: #ffffff;
      border-radius: 6px;

      .file-item {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #333;
        font-size: 15px;
        padding: 20px 15px;

        span {
          flex: auto;

          a {
            color: #333;
            font-size: 15px;
            cursor: pointer;
            text-decoration: none;
          }
        }

        i {
          flex: 0 0 20px;
        }
      }
    }

    .upload-group {
      overflow: hidden;
      padding: 0 15px;
      text-align: left;
    }

    h3 {
      font-size: 18px;
      color: #333;
      margin: 20px 0 5px;
    }

    h3 span {
      font-size: 12px;
      color: #666;
      font-weight: normal;
    }
  }

  .video {
    width: 100%;
    height: 450px;
    background: #000;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #0083f5;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #0083f5;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #0083f5;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #0083f5;
  }
}
</style>

<style lang="scss">
.inspect-index {

  .upload-item .el-loading-spinner .el-icon-loading {
    font-size: 20px;
  }

  .upload-item .el-loading-spinner .el-loading-text {
    font-size: 14px;
    text-align: center;
  }

  .filelist-group .upload-item .el-loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;

    .el-loading-text {
      margin: 0 10px;
    }
  }

  .el-tabs__item {
    font-size: 16px;
    margin-top: 20px;
    font-weight: normal;
  }

  .el-tabs__item.is-active {
    font-weight: bold;
  }

  .admin-score {
    .el-input__inner {
      border-color: #f88000 !important;
      color: #f88000 !important;
      font-size: 13px !important;
      padding: 0 10px !important;
    }

    .el-textarea {
      width: 400px;
    }

    .el-textarea__inner {
      border-color: #f88000 !important;
      color: #f88000 !important;
      font-size: 13px !important;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #f88000;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #f88000;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: #f88000;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #f88000;
    }
  }

  .el-checkbox__label,
  .el-radio__label {
    font-size: 14px;
    color: #555;
    white-space: pre-line;
    line-height: 1.5;
    display: flex;
  }

  .el-checkbox__inner,
  .el-radio__inner {
    margin-top: 3px;
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }

  .el-collapse-item__header {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .el-collapse-item__arrow {
    display: none;
  }

  .max-length {
    .el-upload.el-upload--picture-card {
      display: none;
    }
  }

  .filelist-group .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 1 100%;
    height: 30px;
    width: 345px;
    background: #ffffff;
    padding: 20px 0;
  }

  .filelist-group .el-upload--picture-card {
    height: 50px;
    clear: both;
  }

  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    display: flex;
    float: left;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .el-collapse {
    width: 100%;
    overflow: hidden;
  }

  .el-select .el-input .el-select__caret {
    display: none;
  }

  .el-input--mini .el-input__inner {
    padding: 0 4px;
    color: #0083f5;
    border-color: #0083f5;
    text-align: center;
    font-size: 12px;
    height: 25px;
  }

  .el-input.is-disabled .el-input__inner {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    cursor: not-allowed;
  }

  .el-select-dropdown__item {
    text-align: center;
  }

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    padding-top: 20px;
    font-size: 16px;
  }
}

.customConfirm {
  .el-message-box__message p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .el-message-box__title {
    font-size: 22px;
  }
}

.confirmButtonClass {
  width: 120px;
  margin: 0 20px;
}

.cancelButtonClass {
  width: 120px;
  margin: 0 20px;
}
</style>
